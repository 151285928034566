body {
    margin: 0;
    background: #f9f9f9;
    overflow: hidden;
}

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

html, body, #root {
    height: 100%;
    max-width: 500px;
    margin: auto;
}

#root {
    position: relative;
}

p {
    margin: 0;
    padding: 0;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    z-index: 999;
    background: #fff;

    .left {
        padding-left: 10px;

        .logo {
            max-height: 50px;
            max-width: 100%;
            vertical-align: top;
        }
    }

    .right {
        padding-right: 10px;
        text-align: right;

        .customer-service-wrapper {
            cursor: pointer;
            line-height: 50px;
            display: inline-block;

            .anticon {
                margin-right: 5px;
            }
        }
    }
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    border-top: 1px solid #ddd;

    .menu-item {
        display: block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border-right: 1px solid #ddd;
    }

    .menu-item:last-child {
        border-right: 0;
    }

    .menu-item.active {
        color: #fff;
        background-color: #4dd0e1;
    }
}

.main-panel {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 40px;
    overflow-y: auto;

    .copyright {
        padding: 15px;
        font-size: 12px;
        text-align: center;
    }

    .banner-list {
        .banner-image {
            width: 100%;
        }
    }

    .notice-list {
        margin: 0 5px 5px 5px;

        .rfm-child {
            margin-right: 50px;
        }
    }

    .game-list {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(216, 208, 208, 0.24);

        .game-item {
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            color: #6b6969;
            cursor: pointer;
        }

        .game-item.active {
            background: #00cdcd;
            color: #fff;
        }
    }

    .game-info {
        margin-top: 10px;
        line-height: 40px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(216, 208, 208, 0.24);

        .left {
            padding-left: 10px;

            .game-no {
                font-weight: bold;
                color: #f00;
                font-size: 16px;
                margin-left: 3px;
                margin-right: 3px;
            }
        }

        .right {
            text-align: right;
            padding-right: 10px;

            .countdown {
                background-color: #000;
                color: #fff;
                border-radius: 5px;
                padding: 0 5px;
                margin-left: 5px;
            }
        }
    }

    .content-panel {
        margin-top: 10px;
        background: #fff;
        font-size: 12px;

        .result-list {
            width: 100%;
            text-align: center;
            line-height: 30px;
            color: #717171;
            border-collapse: collapse;

            .row-header {
                background-color: #00afc7;
                color: #fff;
                font-size: 14px;
            }

            .result-item {
                border-bottom: 1px dashed #ebebeb;

                span.label {
                    background: #4dd0e1;
                    border-radius: 50%;
                    color: #fff;
                    display: inline-block;
                    height: 22px;
                    min-width: 22px;
                    line-height: 22px;
                }

                span.count {
                    color: red;
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }

            .result-item:nth-child(odd) {
                background-color: #f9f9f9;
            }

            .predict-label {
                height: 24px;
                line-height: 24px;
                width: 24px;
                max-width: 48px;
                color: #fff;
                background-color: #cccccc;
                display: inline-block;
                margin-right: 3px;
            }

            .predict-label-two {
                width: 40px;
            }

            .predict-label.active {
                background-color: #4dc7d8;
                color: #fff;
            }

            .unknown {
                color: #f44336;
            }

            .exclude {
                color: #f44336;
                margin-right: 3px;
            }
        }

        .combination-type-list {
            margin-bottom: 10px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(216, 208, 208, 0.24);

            .combination-type-item {
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                color: #6b6969;
                cursor: pointer;
            }

            .combination-type-item.active {
                background: #00cdcd;
                color: #fff;
            }
        }
    }
}